import styles from './DesktopMessagingStars.module.scss'
import StarsVideo from '@/components/StarsVideo/StarsVideo'

const DesktopMessagingStars = () => {
  return (
    <>
      <StarsVideo
        className={styles.starsVideo__1}
        type={4}
      />
      <StarsVideo
        className={styles.starsVideo__2}
        type={1}
      />
    </>
  )
}

DesktopMessagingStars.displayName = 'DesktopMessagingStars'

export default DesktopMessagingStars
