import { createContext, useState } from 'react'

export const FileBlobContext = createContext({
  blobData: null,
  setBlobData: () => {},
})

const FileBlobContextProvider = ({ children }) => {
  const [blobData, setBlobData] = useState(null)

  return (
    <FileBlobContext.Provider
      value={{
        blobData,
        setBlobData,
      }}
    >
      {children}
    </FileBlobContext.Provider>
  )
}

FileBlobContextProvider.displayName = 'FileBlobContextProvider'

export default FileBlobContextProvider
