import useStore from '@/store'
import classNames from 'classnames'
import NextLink from 'next/link'
import styles from './Link.module.scss'

const Link = ({ link, children, disabled, className, isDownload }) => {
  const setCurrentPageId = useStore(state => state.setCurrentPageId)

  if (link.includes('https')) {
    return (
      <a
        href={link}
        target="_blank"
        className={className}
        {...(isDownload ? { download: true } : {})}
      >
        {children}
      </a>
    )
  }

  if (link.includes('?p=')) {
    return (
      <span
        className={classNames(className, [styles.stepLink])}
        onClick={() => {
          const pageId = link.split('?p=')[1]
          if (pageId && !disabled) {
            setCurrentPageId(pageId)
          }
        }}
      >
        {children}
      </span>
    )
  }

  return (
    <NextLink
      href={link}
      className={className}
    >
      {children}
    </NextLink>
  )
}

Link.displayName = 'Link'

export default Link
