import classnames from 'classnames'
import styles from './StarsVideo.module.scss'
import { deviceInfo } from '@/utils'
import { useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import useStore from '@/store'

const VIDEOS = {
  1: {
    video: '/assets/video/stars-lowerleft.webm',
    fallback: {
      desktop: '/assets/video/stars-lowerleft-desktop.png',
      mobile: '/assets/video/stars-lowerleft-mobile.png',
    },
  },
  2: {
    video: '/assets/video/stars-lowerright.webm',
    fallback: {
      desktop: '/assets/video/stars-lowerright-mobile.png',
      mobile: '/assets/video/stars-lowerright-mobile.png',
    },
  },
  3: {
    video: '/assets/video/stars-topleft.webm',
    fallback: {
      desktop: '/assets/video/stars-topleft-mobile.png',
      mobile: '/assets/video/stars-topleft-mobile.png',
    },
  },
  4: {
    video: '/assets/video/stars-topright.webm',
    fallback: {
      desktop: '/assets/video/stars-topright-desktop.png',
      mobile: '/assets/video/stars-topright-desktop.png',
    },
  },
}

// type = 1 | 2 | 3 | 4
const StarsVideo = ({ className, type }) => {
  const browserName = deviceInfo.browser.name || ''
  const isSafari = browserName.toLowerCase() === 'safari'
  const isShitBrowser =
    (browserName.toLowerCase() === 'safari' && deviceInfo.browser.majorVersion < 16) ||
    (browserName.toLowerCase() === 'chrome' && deviceInfo.os.name.toLowerCase() === 'ios')
  const videoData = VIDEOS[type]
  const loop = type === 1 || type === 4
  const [videoUrl, setVideoUrl] = useState(null)
  const [loadedVideoData, setLoadedVideoData] = useState(false)
  const resizeKey = useWindowResize()
  const videoRef = useRef()
  const isLowPowerMode = useStore(state => state.isLowPowerMode)

  useEffect(() => {
    if (isSafari) {
      const asMov = videoData.video.replace('webm', 'mov')
      if (window.innerWidth > 768) {
        setVideoUrl(asMov)
      } else {
        setVideoUrl(asMov.replace('.mov', '-mobile.mov'))
      }
    } else {
      setVideoUrl(videoData.video)
    }
  }, [isSafari, videoData, resizeKey])

  if (!videoData || isLowPowerMode === null) return null

  return (
    <div className={classnames(styles.StarsVideo, className, { [styles.loadedVideoData]: loadedVideoData })}>
      {/* <p className={styles.isLowPowerMode}>LOWER POWER MODE: {`${isLowPowerMode}`}</p> */}
      {isShitBrowser || isLowPowerMode === true ? (
        <picture>
          <source
            srcSet={videoData.fallback.desktop}
            media="(min-width: 1000px)"
          />
          <img
            data-media
            src={videoData.fallback.mobile}
            className={styles.image}
            alt=""
          />
        </picture>
      ) : (
        <>
          {videoUrl && (
            <video
              data-media
              ref={videoRef}
              src={videoUrl}
              {...(loop ? { loop: true } : {})}
              autoPlay
              muted
              playsInline
              className={styles.video}
              onLoadedData={() => {
                setTimeout(() => {
                  setLoadedVideoData(true)
                }, 100)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

StarsVideo.displayName = 'StarsVideo'

export default StarsVideo
