const RetryIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23576 7.28786C4.73972 5.81163 6.13865 4.75 7.78554 4.75C9.8566 4.75 11.5355 6.42893 11.5355 8.5C11.5355 10.5711 9.8566 12.25 7.78554 12.25C7.37132 12.25 7.03554 12.5858 7.03554 13C7.03554 13.4142 7.37132 13.75 7.78554 13.75C10.685 13.75 13.0355 11.3995 13.0355 8.5C13.0355 5.6005 10.685 3.25 7.78554 3.25C5.61347 3.25 3.74949 4.56905 2.95092 6.44981L2.24504 4.73442C2.08741 4.35137 1.64911 4.16863 1.26606 4.32625C0.883011 4.48388 0.700268 4.92218 0.857894 5.30523L2.33016 8.88304C2.48779 9.26609 2.92609 9.44883 3.30914 9.2912L3.45728 9.23024C3.53607 9.21178 3.61008 9.1809 3.67713 9.13978L6.88694 7.81893C7.26999 7.66131 7.45274 7.22301 7.29511 6.83996C7.13749 6.45691 6.69918 6.27416 6.31613 6.43179L4.23576 7.28786Z"
        fill="currentColor"
      />
    </svg>
  )
}

RetryIcon.displayName = 'RetryIcon'

export default RetryIcon
