const PlayIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7821 7.17278C12.3663 7.56956 12.3663 8.43044 11.7821 8.82722L1.81188 15.5994C1.14794 16.0503 0.249999 15.5748 0.249999 14.7722L0.25 1.22785C0.25 0.42523 1.14793 -0.0503457 1.81188 0.400628L11.7821 7.17278Z"
        fill="currentColor"
      />
    </svg>
  )
}

PlayIcon.displayName = 'PlayIcon'

export default PlayIcon
