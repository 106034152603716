export const wait = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getPageQueryFromId = id => `p=${id}`

export const getDeviceInfo = () => {
  const isBrowser = typeof window !== 'undefined'

  /* eslint-disable */
  const detect = {
    device: {},
    browser: {},
    os: {},
    bots: {},
    isTouchDevice: isBrowser && ('ontouchstart' in window || navigator.maxTouchPoints > 0),
  }

  if (isBrowser) {
    detect.device = require('@jam3/detect').device
    detect.browser = require('@jam3/detect').browser
    detect.os = require('@jam3/detect').os
    detect.bots = require('@jam3/detect').bots
  }

  /* eslint-disable */

  return detect
}

export const deviceInfo = getDeviceInfo()

export const calculateVhSizing = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01

  // avoid android bouncing by calculating it this way
  const deviceInfo = getDeviceInfo()
  const isMobileChrome = deviceInfo.device.type === 'mobile' && deviceInfo.browser.chrome
  if (isMobileChrome) {
    vh = document.documentElement.clientHeight * 0.01
  }

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  // Set the difference below
  document.documentElement.style.setProperty('--navigation-height', `${window.outerHeight - window.innerHeight}px`)
}
