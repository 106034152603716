import classnames from 'classnames'
import styles from './Layout.module.scss'
import Navigation from '@/components/Navigation/Navigation'
import NavigationContextProvider from '../Navigation/Navigation.context'
import FileBlobContextProvider from '@/contexts/FileBlobContext'
import DesktopMessaging from '../DesktopMessaging/DesktopMessaging'
import SlideUpModal from '../SlideUpModal/SlideUpModal'
import useStore from '@/store'
import { useEffect } from 'react'
import WhiteBg from '../WhiteBg/WhiteBg'
import useWindowResize from '@/hooks/use-window-resize'
import { deviceInfo } from '@/utils'
import DetectLowPowerMode from '../DetectLowPowerMode/DetectLowPowerMode'
import Head from 'next/head'

const Layout = ({ className, children }) => {
  const canInteract = useStore(state => state.canInteract)
  const resizeKey = useWindowResize()

  useEffect(() => {
    document.body.dataset.canInteract = canInteract
    document.body.dataset.browser = deviceInfo.browser.name
    document.body.dataset.device = deviceInfo.device.type
    document.body.dataset.os = deviceInfo.os.name
  }, [canInteract, resizeKey])

  return (
    <FileBlobContextProvider>
      <Head>
        <title>Lindt Moments</title>
        <meta
          name="description"
          content="Lindt Moments"
        />
      </Head>
      <NavigationContextProvider>
        <div className={classnames(styles.Layout, className)}>
          <Navigation />
          <main>{children}</main>
        </div>
        <DesktopMessaging />
        <SlideUpModal />
        <WhiteBg />
        <DetectLowPowerMode />
      </NavigationContextProvider>
    </FileBlobContextProvider>
  )
}

Layout.displayName = 'Layout'

export default Layout
