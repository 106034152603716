import classnames from 'classnames'
import styles from './CenterContent.module.scss'

const CenterContent = ({ className, children }) => {
  return <div className={classnames(styles.CenterContent, className)}>{children}</div>
}

CenterContent.displayName = 'CenterContent'

export default CenterContent
