import Layout from '@/components/Layout/Layout'
import { PAGE_IDS } from '@/data'
import useGetActivePage from '@/hooks/use-get-active-page'
import useGetPageUrl from '@/hooks/use-get-page-url'
import useStore from '@/store'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import '@/styles/global.scss'
import useVhSizing from '@/hooks/use-vh-sizing'
import Head from 'next/head'

export default function App({ Component, pageProps }) {
  const setIsBlankEntry = useStore(state => state.setIsBlankEntry)
  const setFormData = useStore(state => state.setFormData)
  const resetFormData = useStore(state => state.resetFormData)
  const setCompleteFormData = useStore(state => state.setCompleteFormData)
  const setCurrentPageId = useStore(state => state.setCurrentPageId)
  const currentPageId = useStore(state => state.currentPageId)
  const { isNfcPage } = useGetActivePage()
  const router = useRouter()
  const { getPageUrl } = useGetPageUrl()
  useVhSizing()

  useEffect(() => {
    const isBlank = !pageProps.videoUrl
    setIsBlankEntry(isBlank)

    if (!isBlank) {
      setCompleteFormData({
        for: pageProps.messageIsFor,
        from: pageProps.messageIsFrom,
        videoType: pageProps.videoType,
        videoUrl: pageProps.videoUrl,
      })
      setTimeout(() => {
        resetFormData()
      }, 1000)
    }

    if (!isNfcPage) return
    if (currentPageId) return

    setCurrentPageId(isBlank ? PAGE_IDS.IntroDefault : PAGE_IDS.IntroComplete)
    // router.push(getPageUrl(isBlank ? PAGE_IDS.IntroDefault : PAGE_IDS.IntroComplete))
  }, [
    pageProps,
    setIsBlankEntry,
    currentPageId,
    router,
    getPageUrl,
    isNfcPage,
    setFormData,
    setCompleteFormData,
    resetFormData,
    setCurrentPageId,
  ])

  useEffect(() => {
    if (pageProps.messageIsFor) {
      setFormData({ field: 'for', value: pageProps.messageIsFor })

      if (pageProps.messageIsFrom) {
        setFormData({ field: 'from', value: pageProps.messageIsFrom })

        if (pageProps.videoType) {
          setFormData({ field: 'videoType', value: pageProps.videoType })

          if (pageProps.videoUrl) {
            setFormData({ field: 'videoUrl', value: pageProps.videoUrl })
          }
        }
      }
    }
  }, [pageProps, setFormData])

  return (
    <Layout>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no"
        />
      </Head>
      <Component {...pageProps} />
    </Layout>
  )
}
