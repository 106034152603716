const PauseIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.75C3 0.921573 2.32843 0.25 1.5 0.25C0.671573 0.25 0 0.921573 0 1.75V14.25C0 15.0784 0.671573 15.75 1.5 15.75C2.32843 15.75 3 15.0784 3 14.25V1.75ZM10 1.75C10 0.921573 9.32843 0.25 8.5 0.25C7.67157 0.25 7 0.921573 7 1.75V14.25C7 15.0784 7.67157 15.75 8.5 15.75C9.32843 15.75 10 15.0784 10 14.25V1.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

PauseIcon.displayName = 'PauseIcon'

export default PauseIcon
