import { getPageQueryFromId } from '@/utils'
import { useRouter } from 'next/router'

const useGetPageUrl = () => {
  const router = useRouter()

  const getPageUrl = pageId => {
    const query = `?${getPageQueryFromId(pageId)}`
    const nfcId = router.query.nfcId
    const path = `/${nfcId}${query}`
    return path
  }

  return { getPageUrl }
}

useGetPageUrl.displayName = 'useGetPageUrl'

export default useGetPageUrl
