const PhoneIcon = ({ className }) => {
  return (
    <svg
      viewBox="0 0 59 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1.5"
        y="1.5"
        width="56"
        height="93"
        rx="4.5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle
        cx="29.5"
        cy="14.5"
        r="2.5"
        fill="currentColor"
      />
    </svg>
  )
}

PhoneIcon.displayName = 'PhoneIcon'

export default PhoneIcon
