import classnames from 'classnames'
import styles from './Button.module.scss'
import Link from '../Link/Link'
import { useRef } from 'react'
import gsap from 'gsap'

// Icons
import RefreshIcon from '../Icons/RefreshIcon/RefreshIcon'
import RetryIcon from '../Icons/RetryIcon/RetryIcon'
import XIcon from '../Icons/XIcon/XIcon'
import CaretLeftIcon from '../Icons/CaretLeftIcon/CaretLeftIcon'
import ArrowRightIcon from '../Icons/ArrowRightIcon/ArrowRightIcon'
import ArrowLeftIcon from '../Icons/ArrowLeftIcon/ArrowLeftIcon'
import DownloadIcon from '../Icons/DownloadIcon/DownloadIcon'
import CheckmarkIcon from '../Icons/CheckmarkIcon/CheckmarkIcon'
import PlayIcon from '../Icons/PlayIcon/PlayIcon'
import PauseIcon from '../Icons/PauseIcon/PauseIcon'

const VIEWBOX_WIDTH = 315
const VIEWBOX_HEIGHT = 64

const ICONS = {
  refresh: RefreshIcon,
  retry: RetryIcon,
  x: XIcon,
  caretLeft: CaretLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowLeft: ArrowLeftIcon,
  download: DownloadIcon,
  checkmark: CheckmarkIcon,
  play: PlayIcon,
  pause: PauseIcon,
}

const Button = ({
  className,
  el,
  link,
  children,
  onClick,
  linkClassName,
  disabled,
  primary,
  secondary,
  pill,
  diamond,
  diamondFilled,
  label,
  icon,
  isDownload,
  inverse,
  swapButtonLabelPosition,
}) => {
  const containerRef = useRef()
  const Icon = ICONS[icon]
  const bgGroupRef = useRef()

  let El = el || 'button'
  if (link) El = 'span'

  const animateBgOnTouch = isActive => {
    if (!bgGroupRef.current) return
    gsap.killTweensOf(bgGroupRef.current)
    gsap.to(bgGroupRef.current, {
      scale: isActive ? 0.95 : 1,
      y: isActive ? '2.5%' : 0,
      x: isActive ? '2.5%' : 0,
      duration: 0.1,
    })
  }

  const handleTouchStart = () => {
    animateBgOnTouch(true)
  }

  const handleTouchEnd = () => {
    animateBgOnTouch(false)
  }

  const button = (
    <El
      onClick={() => {
        if (onClick) onClick()
      }}
      ref={containerRef}
      className={classnames(
        styles.Button,
        className,
        { [styles.primary]: primary },
        { [styles.secondary]: secondary },
        { [styles.pill]: pill },
        { [styles.diamond]: diamond },
        { [styles.diamondFilled]: diamondFilled },
        {
          [styles.inverse]: inverse,
        },
        {
          [styles.swapButtonLabelPosition]: swapButtonLabelPosition,
        },
      )}
      disabled={disabled}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      {primary && (
        <svg
          className={styles.primaryButtonSvgShape}
          preserveAspectRatio="none"
          viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M311.065 21.7407C314.954 28.0273 314.954 35.9727 311.065 42.2593L303.641 54.2593C300.087 60.0039 293.813 63.5 287.058 63.5L27.942 63.5C21.1868 63.5 14.9129 60.0039 11.3589 54.2593L3.93497 42.2593C0.0456832 35.9727 0.0456836 28.0273 3.93497 21.7407L11.3589 9.74074C14.9129 3.9961 21.1868 0.500012 27.9419 0.500012L287.058 0.500014C293.813 0.500014 300.087 3.99611 303.641 9.74074L311.065 21.7407Z"
            className={styles.outlinePath}
          />
          <g ref={bgGroupRef}>
            <path
              d="M304.093 24.0916C307.099 28.9355 307.099 35.0645 304.093 39.9084L298.509 48.9084C295.773 53.3179 290.952 56 285.763 56L29.237 56C24.0478 56 19.2271 53.3179 16.4911 48.9084L10.9069 39.9084C7.90143 35.0645 7.9014 28.9355 10.9069 24.0916L16.4911 15.0916C19.2271 10.6821 24.0478 8 29.237 8L285.763 8C290.952 8 295.773 10.6821 298.509 15.0916L304.093 24.0916Z"
              className={styles.outlineBg}
            />
          </g>
        </svg>
      )}
      {label && !children && (
        <span
          className={styles.label}
          data-label
        >
          {Icon && (
            <span
              className={styles.iconContainer}
              data-icon={icon}
            >
              <Icon className={styles.icon} />
            </span>
          )}
          {label}
        </span>
      )}
      {Icon && !label && !children && (
        <span
          className={styles.iconContainer}
          data-icon={icon}
        >
          <Icon className={styles.icon} />
        </span>
      )}
      {children && children}
    </El>
  )

  if (link) {
    return (
      <Link
        disabled={disabled}
        className={linkClassName}
        link={link}
        isDownload={isDownload}
      >
        {button}
      </Link>
    )
  }

  return button
}

Button.displayName = 'Button'

export default Button
