import classnames from 'classnames'
import styles from './BottomContent.module.scss'

const BottomContent = ({ className, children }) => {
  return <div className={classnames(styles.BottomContent, className)}>{children}</div>
}

BottomContent.displayName = 'BottomContent'

export default BottomContent
