const XIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10647 1.10647C8.35959 0.853351 8.35959 0.442961 8.10647 0.189841C7.85335 -0.0632802 7.44296 -0.0632802 7.18984 0.189841L4.14803 3.23165L1.10647 0.190084C0.853351 -0.0630363 0.442961 -0.0630364 0.18984 0.190084C-0.0632801 0.443205 -0.0632801 0.853595 0.18984 1.10672L3.2314 4.14828L0.189841 7.18984C-0.0632802 7.44296 -0.0632802 7.85335 0.189841 8.10647C0.442961 8.35959 0.853351 8.35959 1.10647 8.10647L4.14803 5.06491L7.18984 8.10672C7.44296 8.35984 7.85335 8.35984 8.10647 8.10672C8.35959 7.8536 8.35959 7.44321 8.10647 7.19008L5.06467 4.14828L8.10647 1.10647Z"
        fill="currentColor"
      />
    </svg>
  )
}

XIcon.displayName = 'XIcon'

export default XIcon
