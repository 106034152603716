const CheckmarkIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.5L5.33333 9L12 2"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

CheckmarkIcon.displayName = 'CheckmarkIcon'

export default CheckmarkIcon
