import { useEffect } from 'react'
import useWindowResize, { USE_WINDOW_RESIZE_DEFAULTS } from './use-window-resize'
import { calculateVhSizing } from '@/utils'

export default function useVhSizing() {
  const key = useWindowResize({ detectHeightChange: true, debounce: USE_WINDOW_RESIZE_DEFAULTS.debounce * 0.2 })

  useEffect(() => {
    calculateVhSizing()
  }, [key])

  return {}
}
