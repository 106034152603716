import classnames from 'classnames'
import styles from './WhiteBg.module.scss'
import { PAGE_IDS } from '@/data'
import useStore from '@/store'
import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import Grain from '../Grain'

export const WHITE_BG_STEPS = [PAGE_IDS.ReviewVideo, PAGE_IDS.PreRecordedSelect, PAGE_IDS.DownloadVideo]

const WhiteBg = ({ className }) => {
  const currentPageId = useStore(state => state.currentPageId)
  const bgRef = useRef()

  useEffect(() => {
    const isActive = WHITE_BG_STEPS.includes(currentPageId)
    gsap.to(bgRef.current, {
      autoAlpha: isActive ? 1 : 0,
    })
  }, [currentPageId])

  return (
    <div className={classnames(styles.WhiteBg, className)}>
      <div
        className={styles.innerBg}
        ref={bgRef}
      >
        <Grain />
      </div>
    </div>
  )
}

WhiteBg.displayName = 'WhiteBg'

export default WhiteBg
