const RefreshIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23427 3.75C6.58738 3.75 5.18845 4.81163 4.6845 6.28786L6.76486 5.43179C7.14791 5.27416 7.58622 5.45691 7.74384 5.83996C7.90147 6.22301 7.71872 6.66131 7.33567 6.81893L4.12586 8.13978C4.05881 8.1809 3.9848 8.21178 3.90601 8.23024L3.75787 8.2912C3.37482 8.44883 2.93652 8.26609 2.77889 7.88304L1.30662 4.30523C1.149 3.92218 1.33174 3.48388 1.71479 3.32625C2.09784 3.16863 2.53614 3.35137 2.69377 3.73442L3.39966 5.44981C4.19822 3.56905 6.0622 2.25 8.23427 2.25C10.0344 2.25 11.6226 3.15666 12.5672 4.53482C12.8014 4.87647 12.7143 5.34329 12.3727 5.57748C12.031 5.81167 11.5642 5.72455 11.33 5.38289C10.6531 4.39531 9.51892 3.75 8.23427 3.75ZM7.71433 12.8477C9.36122 12.8477 10.7602 11.786 11.2641 10.3098L9.18374 11.1659C8.80069 11.3235 8.36238 11.1407 8.20476 10.7577C8.04713 10.3747 8.22988 9.93635 8.61292 9.77872L11.8227 8.45788C11.8898 8.41675 11.9638 8.38587 12.0426 8.36741L12.1907 8.30645C12.5738 8.14883 13.0121 8.33157 13.1697 8.71462L14.642 12.2924C14.7996 12.6755 14.6169 13.1138 14.2338 13.2714C13.8508 13.429 13.4125 13.2463 13.2548 12.8632L12.5489 11.1478C11.7504 13.0286 9.88639 14.3477 7.71433 14.3477C5.9142 14.3477 4.32602 13.441 3.38136 12.0628C3.14717 11.7212 3.23428 11.2544 3.57594 11.0202C3.91759 10.786 4.38441 10.8731 4.6186 11.2148C5.29554 12.2023 6.42967 12.8477 7.71433 12.8477Z"
        fill="currentColor"
      />
    </svg>
  )
}

RefreshIcon.displayName = 'RefreshIcon'

export default RefreshIcon
