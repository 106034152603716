import { createContext, useState } from 'react'

export const NavigationContext = createContext({
  raf: null,
})

const defaultFunc = () => {}

const NavigationContextProvider = ({ children }) => {
  const [leftCtaFunction, setLeftCtaFunction] = useState(defaultFunc)

  return (
    <NavigationContext.Provider
      value={{
        leftCtaFunction,
        setLeftCtaFunction,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

NavigationContextProvider.displayName = 'NavigationContextProvider'

export default NavigationContextProvider
