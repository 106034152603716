import classnames from 'classnames'
import styles from './Grain.module.scss'

const Grain = ({ className }) => {
  return (
    <div className={classnames(styles.Grain, className)}>
      <div className={styles.texture} />
    </div>
  )
}

Grain.displayName = 'Grain'

export default Grain
