import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const DEFAULT_FORM_VALUES = {
  for: '',
  from: '',
  videoType: '',
  videoUrl: '',
  preRecordedType: '',
}

const useStore = create(
  persist(
    set => ({
      // Global
      canInteract: true,
      setCanInteract: canInteract => set({ canInteract }),
      isLowPowerMode: null, // null | false | true
      setIsLowPowerMode: isLowPowerMode => set({ isLowPowerMode }),

      // Video
      isFullVideo: false,
      setIsFullVideo: isFullVideo => set({ isFullVideo }),

      // Entry Settings
      isBlankEntry: null,
      setIsBlankEntry: isBlankEntry => set({ isBlankEntry }),

      // Modal
      modalKey: 'HOW_THIS_WORKS', // null | 'STORES' | 'HOW_THIS_WORKS'
      setModalKey: modalKey => set({ modalKey }),
      modalIsOpen: false,
      setModalIsOpen: modalIsOpen => set({ modalIsOpen }),

      // Pages
      currentPageId: null,
      setCurrentPageId: currentPageId => set({ currentPageId }),

      // Submitted Data
      completeFormData: {},
      setCompleteFormData: completeFormData => set({ completeFormData }),

      // Entry Info
      formData: DEFAULT_FORM_VALUES,
      setFormData: ({ field, value }) => {
        const formData = useStore.getState().formData
        const newFormData = {
          ...formData,
          [field]: value,
        }
        set({ formData: newFormData })
      },
      resetFormData: () => {
        set({ formData: DEFAULT_FORM_VALUES })
      },
    }),
    {
      name: 'mothers-day-storage',
      partialize: state => ({ isBlankEntry: state.isBlankEntry, formData: state.formData }),
    },
  ),
)

useStore.displayName = 'useStore'

export default useStore
