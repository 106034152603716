const ArrowRightIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1109 1.26179C12.5251 0.676002 11.5753 0.676002 10.9895 1.26179C10.4037 1.84758 10.4037 2.79732 10.9895 3.38311L15.6862 8.07977H1.82227C0.993838 8.07977 0.322266 8.75134 0.322266 9.57977C0.322266 10.4082 0.993838 11.0798 1.82227 11.0798H15.6568L10.9895 15.7471C10.4037 16.3329 10.4037 17.2826 10.9895 17.8684C11.5753 18.4542 12.5251 18.4542 13.1109 17.8684L20.3535 10.6258C20.9393 10.04 20.9393 9.09022 20.3535 8.50443L13.1109 1.26179Z"
        fill="currentColor"
      />
    </svg>
  )
}

ArrowRightIcon.displayName = 'ArrowRightIcon'

export default ArrowRightIcon
