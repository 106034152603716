const ArrowLeftIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68187 17.046C8.26766 17.6317 9.21741 17.6317 9.80319 17.046C10.389 16.4602 10.389 15.5104 9.80319 14.9246L5.10653 10.228L18.9705 10.228C19.7989 10.228 20.4705 9.5564 20.4705 8.72797C20.4705 7.89954 19.7989 7.22797 18.9705 7.22797L5.13588 7.22797L9.80319 2.56066C10.389 1.97487 10.389 1.02513 9.80319 0.439342C9.2174 -0.146445 8.26766 -0.146445 7.68187 0.439342L0.439227 7.68199C-0.14656 8.26777 -0.146559 9.21752 0.439227 9.80331L7.68187 17.046Z"
        fill="currentColor"
      />
    </svg>
  )
}

ArrowLeftIcon.displayName = 'ArrowLeftIcon'

export default ArrowLeftIcon
