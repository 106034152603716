import classnames from 'classnames'
import styles from './DesktopMessaging.module.scss'
import CenterContent from '../CenterContent/CenterContent'
import PhoneIcon from '../Icons/PhoneIcon/PhoneIcon'
import BottomContent from '../BottomContent/BottomContent'
import { TERMS_OF_USE_LINK } from '@/data'
import { useRouter } from 'next/router'
import DesktopMessagingStars from './DesktopMessagingStars/DesktopMessagingStars'

const DesktopMessaging = ({ className }) => {
  const router = useRouter()
  const isHome = router.pathname === '/'

  if (isHome) return

  return (
    <div className={classnames(styles.DesktopMessaging, className)}>
      <DesktopMessagingStars />
      <CenterContent>
        <div className={styles.textContent}>
          <PhoneIcon className={styles.phoneIcon} />
          <h1 className={styles.title}>Use your smartphone to access the experience</h1>
          <p className={styles.description}>
            You&apos;ll be able to add a personalized video to your Lindt Chocolate box.
          </p>
        </div>
      </CenterContent>
      <BottomContent>
        <p className={styles.linkContainer}>
          <a
            className={styles.link}
            href={TERMS_OF_USE_LINK}
            target="_blank"
          >
            General terms of use
          </a>
        </p>
      </BottomContent>
    </div>
  )
}

DesktopMessaging.displayName = 'DesktopMessaging'

export default DesktopMessaging
