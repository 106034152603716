import { useRouter } from 'next/router'

const useGetActivePage = () => {
  const router = useRouter()
  const isNfcPage = router.query.nfcId

  return { isNfcPage }
}

useGetActivePage.displayName = 'useGetActivePage'

export default useGetActivePage
