import classnames from 'classnames'
import styles from './SlideUpModal.module.scss'
import useStore from '@/store'
import XIcon from '../Icons/XIcon/XIcon'
import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import useBreakpoint from '@/hooks/use-breakpoint'

const HOW_THIS_WORKS_STEPS = [
  'Record a video greeting up to 30 seconds within this website',
  'Once you are happy with your video, follow the prompts to upload it',
  'The receiver will be able to tap their phone on the box to watch your video',
]

const SlideUpModal = ({ className }) => {
  const modalKey = useStore(state => state.modalKey)
  const setModalKey = useStore(state => state.setModalKey)
  const modalIsOpen = useStore(state => state.modalIsOpen)
  const setModalIsOpen = useStore(state => state.setModalIsOpen)
  const bgRef = useRef()
  const contentRef = useRef()
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    gsap.set(contentRef.current, { clearProps: 'all' })
  }, [isMobile])

  useEffect(() => {
    if (isMobile === null) return
    if (!bgRef.current || !contentRef.current) return
    gsap.killTweensOf(bgRef.current)
    gsap.killTweensOf(contentRef.current)

    const duration = modalIsOpen ? 0.6 : 0.3
    const ease = 'Power2.easeOut'

    gsap.to(bgRef.current, {
      autoAlpha: modalIsOpen ? 0.8 : 0,
    })

    let propertiesToChange = {
      x: modalIsOpen ? 0 : '100%',
    }

    if (isMobile) {
      propertiesToChange = {
        y: modalIsOpen ? 0 : '100%',
      }
    }

    gsap.to(contentRef.current, {
      ...propertiesToChange,
      duration,
      ease,
    })
  }, [modalIsOpen, setModalKey, isMobile])

  return (
    <div className={classnames(styles.SlideUpModal, className, { [styles.modalIsOpen]: modalIsOpen })}>
      <div
        className={styles.bg}
        ref={bgRef}
        onClick={() => {
          setModalIsOpen(false)
        }}
      />
      <div
        className={styles.modalContent}
        ref={contentRef}
      >
        <div className={styles.modalContent__inner}>
          <button
            className={styles.closeButton}
            aria-label="close modal"
            onClick={() => {
              setModalIsOpen(false)
            }}
          >
            Close <XIcon className={styles.xIcon} />
          </button>
          {modalKey === 'STORES' && (
            <div className={styles.storesContent}>
              <h1 className={styles.storesContent__heading}>Find out more by visiting one of these stores</h1>
              <p className={styles.storesContent__location}>
                <b>Lindt Chocolate Shop</b>
                <br />
                <b>Toronto Eaton Centre</b>
                <br />
                220 Yonge St, CRU #B210A, Toronto, Canada M5B 2H1 
              </p>
              <p className={styles.storesContent__location}>
                <b>Lindt Chocolate Shop</b>
                <br />
                <b>Toronto PATH</b>
                <br />
                130 King Street W, Units CL5, CL6, CL7, Toronto, Canada M5X 1A9
              </p>
            </div>
          )}
          {modalKey === 'HOW_THIS_WORKS' && (
            <div className={styles.howThisWorksContent}>
              <h1 className={styles.howThisWorksContent__title}>How does this work?</h1>
              <ul className={styles.howThisWorksContent__list}>
                {HOW_THIS_WORKS_STEPS.map((item, i) => (
                  <li
                    className={styles.howThisWorksContent__listItem}
                    key={i}
                  >
                    <span className={styles.howThisWorksContent__listItemNumber}>{i + 1}.</span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SlideUpModal.displayName = 'SlideUpModal'

export default SlideUpModal
