const DownloadIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0.25C5.41421 0.25 5.75 0.585786 5.75 1V7.18934L8.46967 4.46967C8.76256 4.17678 9.23744 4.17678 9.53033 4.46967C9.82322 4.76256 9.82322 5.23744 9.53033 5.53033L5.53033 9.53033C5.23744 9.82322 4.76256 9.82322 4.46967 9.53033L0.46967 5.53033C0.176777 5.23744 0.176777 4.76256 0.46967 4.46967C0.762563 4.17678 1.23744 4.17678 1.53033 4.46967L4.25 7.18934V1C4.25 0.585786 4.58579 0.25 5 0.25ZM7.75 13C7.75 13.4142 7.41421 13.75 7 13.75H3C2.58579 13.75 2.25 13.4142 2.25 13C2.25 12.5858 2.58579 12.25 3 12.25H7C7.41421 12.25 7.75 12.5858 7.75 13Z"
        fill="currentColor"
      />
    </svg>
  )
}

DownloadIcon.displayName = 'DownloadIcon'

export default DownloadIcon
