import classnames from 'classnames'
import styles from './Navigation.module.scss'
import { PAGE_IDS, VIDEO_TYPES } from '@/data'
import { useContext, useState } from 'react'
import Button from '@/components/Button/Button'
import useStore from '@/store'
import { useEffect } from 'react'
import useGetPageUrl from '@/hooks/use-get-page-url'
import Image from 'next/image'
import { NavigationContext } from './Navigation.context'
import { WHITE_BG_STEPS } from '../WhiteBg/WhiteBg'

const TEXT_BY_IDS = {
  BACK: [
    PAGE_IDS.EnterFor,
    PAGE_IDS.EnterFrom,
    PAGE_IDS.SelectCaptureOrPrerecord,
    PAGE_IDS.Capture,
    PAGE_IDS.DownloadVideo,
    PAGE_IDS.PreRecordedSelect,
  ],
  CANCEL: [],
}

const Navigation = ({ className }) => {
  const [activeText, setActiveText] = useState(null)
  const formData = useStore(state => state.formData)
  const currentPageId = useStore(state => state.currentPageId)
  const { getPageUrl } = useGetPageUrl()
  const { leftCtaFunction, setLeftCtaFunction } = useContext(NavigationContext)
  const isWhiteBg = WHITE_BG_STEPS.includes(currentPageId)
  const isFullVideo = useStore(state => state.isFullVideo)

  const ctaLinks = {
    [PAGE_IDS.EnterFor]: PAGE_IDS.IntroDefault,
    [PAGE_IDS.EnterFrom]: PAGE_IDS.EnterFor,
    [PAGE_IDS.SelectCaptureOrPrerecord]: PAGE_IDS.EnterFrom,
    [PAGE_IDS.Capture]: PAGE_IDS.SelectCaptureOrPrerecord,
    [PAGE_IDS.PreRecordedSelect]: PAGE_IDS.SelectCaptureOrPrerecord,
    [PAGE_IDS.DownloadVideo]: PAGE_IDS.IntroComplete,
    [PAGE_IDS.Complete]: formData.videoType === VIDEO_TYPES.CUSTOM ? PAGE_IDS.Capture : PAGE_IDS.PreRecordedSelect,
  }

  const pageIdToLinkTo = ctaLinks[currentPageId]

  let ctaButtonText = null
  switch (activeText) {
    case 'BACK':
      ctaButtonText = 'Back'
      break
    case 'CANCEL':
      ctaButtonText = 'Cancel'
      break
    default:
      break
  }

  useEffect(() => {
    if (TEXT_BY_IDS.BACK.includes(currentPageId)) {
      return setActiveText('BACK')
    }
    if (TEXT_BY_IDS.CANCEL.includes(currentPageId)) {
      return setActiveText('CANCEL')
    }

    return setActiveText(null)
  }, [currentPageId])

  return (
    <header
      className={classnames(
        styles.Navigation,
        className,
        { [styles.whiteBg]: isWhiteBg && !isFullVideo },
        { [styles.isFullVideo]: isFullVideo },
      )}
    >
      <div className={styles.topLeftContainer}>
        {activeText && (
          <Button
            linkClassName={styles.cta}
            link={getPageUrl(pageIdToLinkTo)}
            secondary
            onClick={() => {
              if (leftCtaFunction) leftCtaFunction()
              setLeftCtaFunction(() => {})
            }}
            icon="caretLeft"
            label={ctaButtonText}
          />
        )}
      </div>
      <div className={styles.logoContainer}>
        <Image
          alt="Lindt"
          src="/assets/logo.png"
          width={1000}
          height={200}
          className={styles.logo}
          priority
        />
      </div>
    </header>
  )
}

Navigation.displayName = 'Navigation'

export default Navigation
