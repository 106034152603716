export const PAGE_IDS = {
  AcceptVideo: 'AcceptVideo',
  Capture: 'Capture',
  Complete: 'Complete',
  DownloadVideo: 'DownloadVideo',
  EnterFor: 'EnterFor',
  EnterFrom: 'EnterFrom',
  IntroComplete: 'IntroComplete',
  IntroDefault: 'IntroDefault',
  PreRecordedSelect: 'PreRecordedSelect',
  SelectCaptureOrPrerecord: 'SelectCaptureOrPrerecord',
  Submitting: 'Submitting',
  ViewVideo: 'ViewVideo',
  ReviewVideo: 'ReviewVideo',
}

export const VIDEO_TYPES = {
  CUSTOM: 'custom',
  PRE_RECORDED: 'preRecorded',
}

export const PRE_RECORDED_TYPES = {
  BIRTHDAY: 'birthday',
  MOTHERS_DAY: 'mothersday',
  JUST_BECAUSE: 'justbecause',
  THANK_YOU: 'thankyou',
}

export const VIDEO_LENGTH_SECONDS = 30

export const TERMS_OF_USE_LINK = '/assets/terms-and-conditions.pdf'

export const EXIT_PAGE_LINK = 'https://www.lindt.ca/'
