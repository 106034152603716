const CaretLeftIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.64795 8.14838L1.14795 4.64838L4.64795 1.14838"
        stroke="currentColor"
        strokeWidth="1.29631"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

CaretLeftIcon.displayName = 'CaretLeftIcon'

export default CaretLeftIcon
